import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import parseISO from 'date-fns/parseISO'
import { useMemo } from 'react'
import {
    ProductType,
    UserPlantInfo
} from '../../../graphql/generated/autogenerated'
import { PlantType } from '../../enums/plantTypes'
import { useCurrentDateTime } from '../useCurrentDateTime/useCurrentDateTime'
import useFlagStore, { FlagKey } from '../useFlagStore/useFlagStore'
import { usePlantsContext } from '../usePlantsProvider'

export const useCurrentPlant = () => {
    const { plantedPlants, activePlants, products, isLoading } =
        usePlantsContext()
    const now = useCurrentDateTime()
    const { getFlag, loading: flagsLoading } = useFlagStore()

    return useMemo(() => {
        const productsByUpc = products?.reduce((acc, product) => {
            acc[product.upc] = product
            return acc
        }, {} as Record<string, (typeof products)[0]>)

        let currentPlant: UserPlantInfo | undefined = activePlants.reduce(
            (earliest, current) => {
                return new Date(earliest.createdAt) <
                    new Date(current.createdAt)
                    ? earliest
                    : current
            },
            activePlants[0]
        )
        const latestPlant: UserPlantInfo | undefined = [...plantedPlants]
            .filter((p) => {
                const isTrophyPlant =
                    productsByUpc[p.productUpc].productTypeEnum ===
                        ProductType.TrophyPlant ||
                    productsByUpc[p.productUpc].productType ===
                        ProductType.TrophyPlant ||
                    !!p.challengeId

                return !isTrophyPlant || p.productUpc === PlantType.novafutura
            })
            .sort((a, b) => {
                const dateA = parseISO(a.updatedAt)
                const dateB = parseISO(b.updatedAt)
                return dateB.getTime() - dateA.getTime()
            })[0]

        const latestIsOnboardingPlant =
            latestPlant?.productUpc === PlantType.novafutura

        const interactedWithOnboarding = Boolean(
            getFlag(FlagKey.ONBOARDING_PLANT_INTERACTED)
        )

        const hideOnboardingPlant =
            (latestIsOnboardingPlant && interactedWithOnboarding) ||
            flagsLoading

        if (!currentPlant && latestPlant && !hideOnboardingPlant) {
            const daysSincePlanted = differenceInCalendarDays(
                now,
                parseISO(latestPlant.updatedAt)
            )

            if (latestIsOnboardingPlant && daysSincePlanted <= 5) {
                currentPlant = latestPlant
            } else if (daysSincePlanted <= 1) {
                currentPlant = latestPlant
            }
        }

        return {
            isLoading,
            currentPlant,
            currentPlantProduct: productsByUpc[currentPlant?.productUpc],
            isActivePlant: !!currentPlant && activePlants?.length > 0,
            isOnboardingPlant:
                currentPlant?.productUpc === PlantType.novafutura,
            plantedPlants,
            activePlants,
            products
        }
    }, [
        activePlants,
        plantedPlants,
        getFlag,
        flagsLoading,
        products,
        isLoading,
        now
    ])
}
